export const API_URL = 'https://api.marduknik.am';
export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

class FetchService {
  get(path) {
    return this.request(path, HTTP_METHODS.GET);
  }

  post(path, body) {
    return this.request(path, HTTP_METHODS.POST, body);
  }

  patch(path, body) {
    return this.request(path, HTTP_METHODS.PATCH, body);
  }

  async request(path, method, body = null) {
    const url = `${API_URL}/api${path}`;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    return fetch(url, {
      ...(body ? { body: JSON.stringify(body) } : {}),
      method,
      headers: myHeaders,
    })
      .then((res) => {
        if (!res.ok) {
          res.json().then((data) => {
            if (data.errors) {
              console.log(data.errors);
            }
          });
          throw new Error();
        }
        return res.json();
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}

const fetchService = new FetchService();
export default fetchService;
