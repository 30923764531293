import { useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import fetchService from './services/fetchService';
import { translations } from './translations'
import img1 from './assets/images/dressCodes/1.JPG';
import img2 from './assets/images/dressCodes/2.JPG';
import img3 from './assets/images/dressCodes/3.JPG';
import img4 from './assets/images/dressCodes/4.JPG';
import img5 from './assets/images/dressCodes/5.JPG';
import IMG_3963 from './assets/images/small/IMG_3963.png';
import IMG_5728 from './assets/images/small/IMG_5728.png';
import IMG_5730 from './assets/images/small/IMG_5730.png';
import IMG_2763 from './assets/images/small/IMG_2763.png';

// MP3
import yourEyes from './assets/music/yourEyes.mp3';

const languages = ['arm', 'rus', 'eng'];

const getFromQuery = (key) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key)
};

const setToQuery = (value, key = 'lang',) => {
    const url = new URL(window.location);
    const currentLang = url.searchParams.get(key);
    document.documentElement.classList.remove(currentLang)
    url.searchParams.set(key, value);
    document.documentElement.classList.add(value)
    window.history.pushState(null, '', url.toString());
};

const defaultGuestModel = { name: '', hasError: false }


const Invitation = () => {
    const langFromQuery = getFromQuery('lang')
    const [selectedLanguage, setSelectedLanguage] = useState(langFromQuery && languages.includes(langFromQuery) ? langFromQuery : languages[0]);
    const [guests, setGuests] = useState([{...defaultGuestModel}]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isShowFinalPage, setIsShowFinalPage] = useState(false);
    const [isMusicScene, setIsMusicScene] = useState(true);
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);
    const [yourEyesAudio] = useState(new Audio(yourEyes));
    const [isComing, setIsComing] = useState(1);
    const [fontsReady, setFontsReady] = useState(false);
    const [wishes, setWishes] = useState('');

    const playAndPauseMusic = () => {
        if (yourEyesAudio) {
            if (isMusicPlaying) {
                yourEyesAudio.pause();
                setIsMusicPlaying(false);
            } else {
                if (isMusicScene) {
                    setIsMusicScene(false);
                }
                yourEyesAudio.addEventListener('ended', function () {
                    this.currentTime = 0;
                    this.play().then(() => {
                        // res
                    });
                }, false);

                yourEyesAudio.play().then(() => {
                    setIsMusicPlaying(true);
                });
            }
        }
    }

    const currentTranslations = useMemo(() => {
        return translations[selectedLanguage] || translations['arm']
    }, [selectedLanguage])

    const guestCount = useMemo(() => {
        return guests.filter(item => item.name).length
    }, [guests]);

    const updateGuestName = (index, value) => {
        const updatedGuests = [...guests];
        updatedGuests[index].name = value;
        updatedGuests[index].hasError = false;
        setGuests(updatedGuests)
    }

    const addInputForName = (index) => {
        const updatedGuests = [...guests];

        if (index === guests.length - 1) {
            updatedGuests.push({...defaultGuestModel})
        } else {
            updatedGuests.splice(index, 1)
        }

        setGuests(updatedGuests)
    }

    useEffect(() => {
        setToQuery(selectedLanguage)
    }, [selectedLanguage]);

    const sendRequestForComing = () => {
        const indexOfEmptyNameGuest = guests.findIndex((item) => !item.name)

        if (indexOfEmptyNameGuest > -1) {
            const updatedGuests = [...guests];
            updatedGuests[indexOfEmptyNameGuest].hasError = true;
            setGuests(updatedGuests)
        } else {
            if (!buttonLoading) {
                const names = guests.map(({name}) => name);

                setButtonLoading(true)
                fetchService.post('/marduknik', { names, ...(wishes ? { wishes } : {}), isComing: !!isComing ? 'YES' : 'NO' }).then(() => {
                    setIsShowFinalPage(true);
                }).catch(() => {
                    setButtonLoading(false);
                })
            }
        }
    }

    useEffect(() => {
        if (isMusicScene) {
            document.documentElement.classList.add('is-music-scene');
        } else {
            document.documentElement.classList.remove('is-music-scene');
        }
    }, [isMusicScene])

    useEffect(() => {
        if (!isMusicScene && yourEyesAudio) {
            document.addEventListener('visibilitychange', () => {
                if (document.hidden) {
                    yourEyesAudio.pause();
                    setIsMusicPlaying(false);
                }
                // else {
                //     yourEyesAudio.play();
                // }

            })
        }
    }, [isMusicScene, yourEyesAudio])

    useEffect( () => {
        async function fontReadyState() {
            await document.fonts.ready;
            setFontsReady(true);
        }

        fontReadyState()
    }, [])

    const {
        FINAL_TEXT_1,
        FINAL_TEXT_2,
        TITLE_1,
        NAMES,
        DATE,
        DEARS,
        FOUND_LOVE,
        DATE_1,
        TIME_LINE_TITLE,
        TIME_LINE_DATE_1,
        TIME_LINE_FOR_WHAT_1,
        VILLA_AZURE,
        TIME_LINE_DATE_2,
        TIME_LINE_FOR_WHAT_2,
        CHURCH,
        TIME_LINE_DATE_3,
        TIME_LINE_FOR_WHAT_3,
        RESTAURANT,
        HOW_TO_GET_TITLE,
        HOW_TO_GET_VILLA_ADDRESS,
        HOW_TO_GET_CHURCH_ADDRESS,
        HOW_TO_GET_RESTAURANT_ADDRESS,
        HOW_TO_GET_MAP,
        DRESS_CODE,
        DRESS_CODE_DEARS,
        DRESS_CODE_DEARS_1,
        PLEASE_CONFIRM,
        PLEASE_CONFIRM_1,
        YES_WE_GO,
        NO_WE_NOT_GO,
        INVITE_SUBMIT,
        INVITE_SUBMIT_1,
        INVITE_SUBMIT_2,
        INVITE_SUBMIT_3,
        CLICK,
        DAY,
        HOUR,
        MINUTE,
        SECOND,
        HOW_IT_WAS
    } = currentTranslations


  return fontsReady ? (
      <div className="mainWrapper">
          {
              isMusicScene && (
                  <div className='click-to-play'>
                      <div className='click-to-play-text'>
                          {CLICK}
                      </div>
                      <div className='click-to-play-button' onClick={() => playAndPauseMusic()}>
                          &#9658;
                      </div>
                  </div>
              )
          }
          {
              isShowFinalPage ?
                  <div className='final-page'>
                      <div className={`final-page_wrp ${!!isComing ? 'is-coming' : ''}`}>
                          {!!isComing && <div>{TITLE_1}</div>}
                          <div>{!!isComing ?  FINAL_TEXT_1 : FINAL_TEXT_2}</div>
                      </div>
                  </div>
                  :
                  <>
                      <div className="mainImgHolder">
                          {/*<video className="mainImg" autoPlay loop playsInline muted src={menqVideo} />*/}
                          <img
                              src={IMG_5728}
                              alt="" className="mainImg"/>
                          <h1 className="title">{TITLE_1}</h1>
                          <div className="musicSettings" onClick={() => playAndPauseMusic()}>
                              {
                                  isMusicPlaying ? <>&#8545;</> : <>&#9658;</>
                              }
                          </div>
                          <div className="languageSettings">
                              {
                                  languages.map((item) => (
                                      <span key={item} className={`languageName ${item === selectedLanguage ? 'active' : ''}`} onClick={() => setSelectedLanguage(item)}>{item}</span>
                                  ))
                              }
                          </div>
                          <div className="weddingInfoRow">
                              <h3 className="peoplesNames">{NAMES}</h3>
                              <p className="weddingDate">{DATE}</p>
                          </div>
                          <div className="weddingInfoRow">
                              <Countdown date={+new Date('2024-06-15T08:30:00.000Z')} className="peoplesNames" renderer={({ days, hours, minutes, seconds, completed }) => {
                                  if (completed) {
                                      return (
                                          <div className='countdown-wrp'>
                                            <div className='countdown-wrp-item'>
                                                <span>
                                                    {HOW_IT_WAS}
                                                </span>
                                            </div>
                                          </div>
                                      );
                                  } else {
                                      // Render a countdown
                                      return <div className='countdown-wrp'>
                                          <div className='countdown-wrp-item'>
                <span>
                    {days}
                </span>
                                              <span>
                    {DAY}
                </span>
                                          </div>
                                          <div className='countdown-wrp-item'>
                <span>
                    {hours}
                </span>
                                              <span>
                    {HOUR}
                </span>
                                          </div>
                                          <div className='countdown-wrp-item'>
                <span>
                    {minutes}
                </span>
                                              <span>
                    {MINUTE}
                </span>
                                          </div>
                                          <div className='countdown-wrp-item'>
                <span>
                    {seconds}
                </span>
                                              <span>
                    {SECOND}
                </span>
                                          </div>
                                      </div>;
                                  }
                              }}>
                                  <div>
                                      41234234
                                  </div>
                              </Countdown>
                          </div>
                      </div>
                      <div className="guestsInfo container">
                          <div className="guestsInfoColumn">
                              <h3 className="guestsInfoTitle">{DEARS}</h3>
                              <p className="guestsDescription">&emsp;{FOUND_LOVE}</p>
                              <span className="guestsDateInfo">{DATE_1}</span>
                          </div>
                          <div className="guestsInfoImgColumn">
                              <img
                                  src={IMG_5730}
                                  alt="" className="guestImg" />
                              <img
                                  src={IMG_3963}
                                  alt="" className="guestImg" />
                          </div>
                      </div>
                      <div className="weddingTimeInfo container">
                          <h3 className="weddingTimeTitle">{TIME_LINE_TITLE}</h3>
                          <div className="weddingTimeInfoInner">
                              <div className="weddingTimeInfoColumn">
                                  <p className="weddingTime">{TIME_LINE_DATE_1}</p>
                                  <p className="weddingType">{TIME_LINE_FOR_WHAT_1}</p>
                                  <address className="weddingAddressInfo">{VILLA_AZURE}</address>
                              </div>
                              <div className="weddingTimeInfoColumn">
                                  <p className="weddingTime">{TIME_LINE_DATE_2}</p>
                                  <p className="weddingType">{TIME_LINE_FOR_WHAT_2}</p>
                                  <address className="weddingAddressInfo">{CHURCH}</address>
                              </div>
                              <div className="weddingTimeInfoColumn">
                                  <p className="weddingTime">{TIME_LINE_DATE_3}</p>
                                  <p className="weddingType">{TIME_LINE_FOR_WHAT_3}</p>
                                  <address className="weddingAddressInfo">{RESTAURANT}</address>
                              </div>
                          </div>
                      </div>
                      <div className="weddingAddress">
                          <img src={IMG_2763} alt=""
                               className="mainImg second" />
                          <div className="weddingAddressInner container">
                              <div className="weddingAddressContent">
                                  <h3 className="weddingTimeTitle">{HOW_TO_GET_TITLE}</h3>
                                  <div className="weddingAddressRow">
                                      <address className="weddingAddressName">{VILLA_AZURE}</address>
                                      <p className="weddingAddressDesc">{HOW_TO_GET_VILLA_ADDRESS}</p>
                                      <a href="https://yandex.com/maps/-/CDRCqSNc" target='_blank' rel="noreferrer">
                                          <button className="button">
                                              {HOW_TO_GET_MAP}
                                          </button>
                                      </a>
                                  </div>
                                  <div className="weddingAddressRow">
                                      <address className="weddingAddressName">{CHURCH}</address>
                                      <p className="weddingAddressDesc">{HOW_TO_GET_CHURCH_ADDRESS}</p>
                                      <a href="https://yandex.com/maps/-/CDRPfDJR" target='_blank' rel="noreferrer">
                                          <button className="button">
                                              {HOW_TO_GET_MAP}
                                          </button>
                                      </a>
                                  </div>
                                  <div className="weddingAddressRow">
                                      <address className="weddingAddressName">{RESTAURANT}</address>
                                      <p className="weddingAddressDesc">{HOW_TO_GET_RESTAURANT_ADDRESS}</p>
                                      <a href="https://yandex.com/maps/-/CDRPfD2f" target='_blank' rel="noreferrer">
                                          <button className="button">
                                              {HOW_TO_GET_MAP}
                                          </button>
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="weddingInformation">
                          <div className="weddingInformationRow">
                              <div className="weddingInformationRowInner container">
                                  <h3 className="weddingTimeTitle">{DRESS_CODE}</h3>
                                  <div className="weddingInformationCard">
                                      {DRESS_CODE_DEARS}<br />
                                      {DRESS_CODE_DEARS_1}
                                  </div>
                                  <div className="dressCodeTypes">
                                      <div className="dressCodeColors">
                                          <span className="dressCodeColor" style={{ backgroundColor: '#d29d71' }} />
                                          <span className="dressCodeColor" style={{ backgroundColor: '#b07b53' }} />
                                          <span className="dressCodeColor" style={{ backgroundColor: '#8f4f33' }} />
                                          <span className="dressCodeColor" style={{ backgroundColor: '#6a311a' }} />
                                          <span className="dressCodeColor" style={{ backgroundColor: '#3b1b14' }} />
                                      </div>
                                      <div className="dressCodeImages">
                                          <img src={img1}
                                               alt=""
                                               className="dressCodeImg" />
                                          <img src={img2}
                                               alt=""
                                               className="dressCodeImg" />
                                          <img src={img3}
                                               alt=""
                                               className="dressCodeImg" />
                                          <img src={img4}
                                               alt=""
                                               className="dressCodeImg" />
                                          <img src={img5}
                                               alt=""
                                               className="dressCodeImg" />
                                          {/*<img src={img6}*/}
                                          {/*    alt=""*/}
                                          {/*    className="dressCodeImg" />*/}
                                          {/*<img src={img7}*/}
                                          {/*    alt=""*/}
                                          {/*    className="dressCodeImg" />*/}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="weddingForm container">
                      <div className="weddingFormHead">
                          <h3 className="formTitle">{PLEASE_CONFIRM}</h3>
                          <p className="formDesc">{PLEASE_CONFIRM_1}</p>
                      </div>
                      <div className="formInputs">
                          <label className="formRadioRow">
                              <input type="radio" className="inputRadio" name="radio" value={1} checked={!!isComing} onChange={(e) => setIsComing(1)} />
                              <i className="radioImitation" />
                              <span className="formRadioText">{YES_WE_GO}</span>
                          </label>
                          <label className="formRadioRow">
                              <input type="radio" className="inputRadio" name="radio" value={0} onChange={(e) => setIsComing(0)} />
                              <i className="radioImitation" />
                              <span className="formRadioText">{NO_WE_NOT_GO}</span>
                          </label>
                      </div>
                      <div className="formInputs hasGap">
                          <p className="formDesc">{INVITE_SUBMIT}</p>
                          {
                              guests.map(({ name, hasError }, index) => (
                                  <div className='input-and-button' key={index}>
                                      <input value={name} onChange={(e) => {
                                          updateGuestName(index, e.target.value)
                                      }} type="text" className={`input ${hasError ? 'error' : ''}`} placeholder={INVITE_SUBMIT_1} />
                                      <button className="add-button" onClick={() => addInputForName(index)}>
                                          {index === guests.length - 1 ? '+' : '-'}
                                      </button>
                                  </div>
                              ))
                          }
                          <input type="text" className="input no-pointer" readOnly value={guestCount}/>
                          <textarea className="input text-area" onChange={(e) => {
                              setWishes(e.target.value)
                          }} placeholder={INVITE_SUBMIT_3} value={wishes} maxLength={200}/>
                          <button className={`button ${buttonLoading ? 'loading' : ''}`} onClick={sendRequestForComing}>{INVITE_SUBMIT_2}</button>
                      </div>
                  </div>
                  </>
          }
      </div>
) : null
}

export default Invitation;
