import arm from './arm.json'
import rus from './rus.json'
import eng from './eng.json'

const translations = {
    arm,
    rus,
    eng
};

export {
    translations
}